import styled from 'styled-components';
import { respondTo, transition } from 'assets/style/tools/mixin';
import { variables } from 'assets/style/tools/variables';

const Style = {
  Wrap: styled.div`
    width: 100vw;
    margin: 0 auto;
    max-width: 1920px;
    display: grid;
    grid-template-columns: auto 1400px auto;
    min-height: 50vh;
    ${respondTo(`lapt`, `grid-template-columns: auto 1170px auto;`)}
    ${respondTo(`ixmax`, `grid-template-columns: 30px auto 30px;`)}
    ${respondTo(`xsm-s-420`, `grid-template-columns: 20px auto 20px;`)}
    ${respondTo(`xss-s`, `grid-template-columns: 10px auto 10px;`)}
  `,
  Container: styled.div`
    grid-column-start: 2;
    padding: 90px 0 100px;
    text-align: center;
    ${respondTo(`lg`, `padding: 80px 0;`)}
    ${respondTo(`desc`, `padding-top: 60px;`)}
    ${respondTo(`md`, `padding-bottom: 60px;`)}
    ${respondTo(`mds`, `padding-bottom: 80px;`)}
    ${respondTo(`sm`, `padding-top: 40px; padding-bottom: 60px;`)}
  `,
  Title: styled.p`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #354050;
    text-transform: uppercase;
    margin-bottom: 60px;
    ${respondTo(`mds`, `margin-bottom: 40px;`)}
    ${respondTo(`xss-s-340`, `margin-bottom: 30px;`)}
  `,
  Text: styled.p`
    font-size: 234px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;
    ${respondTo(`sm`, `font-size: 192px;`)}
    ${respondTo(`xs`, `font-size: 150px;`)}
    ${respondTo(`xsm-s-420`, `font-size: 120px;`)}
    ${respondTo(`xss-s`, `font-size: 88px;`)}
  `,
  Description: styled.p`
    font-size: 14px;
    line-height: 17px;
    color: #354050;
    max-width: 490px;
    margin: 70px auto 40px;
    ${respondTo(`mds`, `margin-top: 60px;`)}
    ${respondTo(`xs`, `margin-top: 40px;`)}
    ${respondTo(`xsm-s-420`, `margin-bottom: 30px;`)}
    ${respondTo(`xss-s`, `margin-top: 30px;`)}
  `,
  Button: styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    padding: 14px 28px;
    color: ${variables.white};
    background-color: ${variables.accent};
    border: 1px solid ${variables.accent};
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 1px;
    ${transition()};
    &:hover {
      color: ${variables.white};
      background-color: ${variables.accentHover};
    }
    span {
      display: inline-block;
      position: relative;
      padding-right: 25px;
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: 3px;
        width: 15px;
        height: 8px;
        background: url('/img/iconsSvg/404-next-arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  `,
};

export { Style };
