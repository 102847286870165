import React from 'react';
import Head from 'next/head';
import Layout from '@modules/Layout';
import Custom404Content from '@components/404page';

const Custom404 = () => {
  return (
    <Layout>
      <Head>
        <title>404</title>
      </Head>
      <Custom404Content />
    </Layout>
  );
};

export default Custom404;
