import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { Style } from './styles';

function Custom404Content() {
  const { t } = useTranslation('404');
  return (
    <>
      <Style.Wrap>
        <Style.Container>
          <Style.Title>{t('title')}</Style.Title>
          <Style.Text img="/img/404.jpg">404</Style.Text>
          <Style.Description>{t('description')}</Style.Description>
          <Link href={`/companies/`} passHref  prefetch={false}>
            <Style.Button>
              <span>{t('btn_text')}</span>
              {/* <Style.ArrowNextIcon /> */}
            </Style.Button>
          </Link>
        </Style.Container>
      </Style.Wrap>
    </>
  );
}
export default Custom404Content;
